.App {
  text-align: center;
  color: #035a54;
  display: flex;
  margin-top: 8px;
}
header {
  display: none;
}
header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}
.menu button {
  background: none;
  border: none;
}
.nav-chat {
  display: none;
  background-color: #4c4f5f;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.navItems {
  padding: 8px;
  background-color: #212122;
  width: 100%;
  max-width: 340px;
}
.navPromptWrapper {
  /* border-bottom: 1px solid #706e6e; */
  padding: 10px;
  overflow-y: auto;
}
.navPrompt {
  display: flex;
  align-items: center;
  padding: 10px;
  column-gap: 20px;
  margin: 5px 0;
  text-align: left;
}
.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #0297a1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
}

.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}
#botMessage pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;
  top: -45px;
}

@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navCloseIcon {
  width: 20%;
  margin: 10px;
}
nav svg {
  float: left;
}
.sideMenu {
  width: 244px;
  padding: 8px;
  background-color: #f9f9f9;
}

.sideMenu .menuContent {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: calc(100% - 120px);
}

.sideMenuButton {
  border: 1px solid #016c74;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  background-color: #0297a1;
  color:white
}
.navPrompt:hover{
  cursor: pointer;
  background-color: #caeff1;
}
.sideMenuButton:hover {
  cursor: pointer;
  background-color: #016c74;
}
.sideMenuButton span {
  font-size: 20px;
  font-weight: 800;
  padding-left: 6px;
  padding-right: 10px;
}

.test-app .chatBox{
  height: calc(100vh - 220px);
}

.test-app  .inputPromptWrapper{
  position: relative;
}

.test-app  .chatLogWrapper{
  height: calc(100vh - 350px);
}
.chatBox {
  flex: 1;
  position: static;
  line-height: 24px;
  color: #000000;
  font-size: 16px;
  margin-left:10%;
  margin-right: 10%;
  height: calc(100vh - 200px);
}
#introsection::before,
#introsection::after {
  content: "";
  float: left;
  color: rgb(0, 134, 244);
}
#introsection {
  text-align: left;
  padding: 20px;
}
#introsection h1 {
  padding-top: 20px;
  line-height: 33px;
}
#introsection h2 {
  font-size: 16px;
  font-weight: 400;
}
#introsection ul {
  list-style-type: square;
}
#introsection pre {
  margin: 0;
  display: inline;
}
.chatLogWrapper {
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.icon-chat{
  vertical-align: middle;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(243, 241, 241);
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color:#575969 ;
}

::-moz-scrollbar {
  width: 5px;
  background-color: rgb(243, 241, 241);
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}


.chatPromptWrapper {
  max-width: 1000px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.userSVG {
  transform: scale(0.6);
}
.botMessageMainContainer {
  width: 100%;
  background-color: white;
}
.botMessageWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.chatLog {
  display: flex;
  flex-flow: column;
}

.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}
#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

.inputPromptWrapper {
  padding: 8px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  padding-top: 20px;
}
@media only screen and (max-width: 768px){
  .inputPromptWrapper {
    padding: 8px;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    padding-top: 20px;
    margin-bottom: 20px;
  }
} 

.inputPrompttTextarea {
  width: 55%;
  max-height: 180px;
  height: 40px;
  padding: 10px;
  resize: none;
  font-size: 16px;
  background-color: #f9f9f9;
  color: #161515;
  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  overflow-y: hidden;
}

/* @media screen and (min-width: 768px) {
  .inputPromptWrapper {
    position: absolute;
  }
} */

@media screen and (max-width: 1024px) {
  .App {
    display: block;
  }
  header {
    display: flex;
    align-items: center;
    background: #353441;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    padding: 4px 0 0 12px;
  }
  .nav-chat {
    display: flex;
  }
  .sideMenu {
    display: none;
  }
  .chatBox {
    position: static;
  }
 
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 25px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }

  .icon-rtl {
    padding-right: 25px;
    background: url("/src/icons8-sent-50.png") no-repeat right;
    background-size: 20px;
    font-size: 1.2em;
   }

   .csstext{
    font-size: 70px;
    font-weight: 600;
    background-image: linear-gradient(to left, #024a53, #03969b);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
}
.dis{
  pointer-events: none;
  opacity: .5;
}
.item-select{
  cursor: pointer;
  transition: all .2s;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 16px
}
.item-select.active{
  border: 1px solid #007bff !important;
  color: #007bff !important;
  opacity: 1;
}
.item-select:hover{
  border: 1px solid #007bff !important;
  color: #007bff !important;
  opacity: .5 !important;
}
.effect:hover{
  opacity: .5;
  position: relative;
  transition: all .5s ;
}
.effect:hover::after{
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  background-color: #000;
  opacity: .1;
}
.full{
  display: flex;
  flex-direction: column;
}
.full>.border{
  flex: 1;
}
.btn-view:hover{
  opacity: .5;
  color:#007bff
}
.btn-view>span{
  display: block;
  transition: all .1s linear;
  transform: rotate(-90deg);
}
.btn-view>span.active{
  transform: rotate(0);
}
.btn-close{
  opacity: 0;
  position: absolute;
  right: .5rem;
  top: .5rem;
}
.imgUpdate:hover > .btn-close{
  opacity: 1;
}
[title="Cell"]{
  display: none !important;
}
.tox-dialog__body-nav{
  display: none !important;
}