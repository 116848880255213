.login_container {
    width:400px;
    height: 100%;
    margin: 20px auto;
    /* background-color: rgb(243, 248, 248); */
    border: none;
    border-radius: 20px;
}
.login_header {
    color: teal;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.form_login {
    padding: 0px;
    
}


.font_text {
    font-size: 16px;
    color: teal;
    font-weight: bold;
}
.button_login {
    width: 100%;
    height: 38px;
    border: none;
    border-radius: 5px;
    padding: 0 20px;
    background-color: teal;
    outline: none;
    font-size: 18px;
    color: white;
    font-weight: bold;
}
 .input_text{
    width: 100%;
    
  }

  .forgot_container {
    width:400px;
    height: 40vh;
    margin: 20px auto;
    /* background-color: rgb(243, 248, 248); */
    border-radius: 20px;
    border: none;
   
}

.forgot_header {
    color: teal;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.Reset_container {
    width:400px;
    height: 40vh;
    margin: 20px auto;
    /* background-color: rgb(243, 248, 248); */
    border-radius: 20px;
    border: none;

   
}

.Reset_header {
    color: teal;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.profile_container {
    width: 400px;
    /* height: 40vh; */
    margin: 20px auto;
    /* background-color: white; */
    border-radius: 20px;
    border: none;
   
}

.updateRole_container {
    width: 600px;
    /* height: 40vh; */
    margin: 20px auto;
    /* background-color: white; */
    border-radius: 20px;
    border: none;
   
}
.setting_container {
    width:80%;
    height: 50vh;
    margin: 20px auto;
    background-color: white;
    border-radius: 20px;
    border: none;

   
}

.button_setting {
    width: 20%;
    height: 38px;
    border: none;
    border-radius: 5px;
    margin: 20px;
    float: right;
    background-color: teal;
    outline: none;
    font-size: 18px;
    color: white;
    font-weight: bold;
}

.button_cancle {
    width: 20%;
    height: 38px;
    border: none;
    border-radius: 5px;
    margin: 20px;
    float: right;
    background-color: teal;
    outline: none;
    font-size: 18px;
    color: white;
    font-weight: bold;
}

.uploadDocument_container {
    width:50%;
    height: 50vh;
    margin: 20px auto;
    background-color: white;
    border-radius: 20px;
    border: none;

   
}

.DocumentManagement_container {
    width:85%;
    height: 100%;
    margin: 20px auto ;
    background-color: white;
    border-radius: 20px;
    border: none;

   
}
.document_header {
    color: teal;
    text-align: left;
    font-size: 25px;
    font-weight: bold;
}

._navPrompt {
    /* font-Weight:bold; */
    border-Right:1;
    border-Radius:5px;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0;
   
}   

._navPrompt p {
    font-size: 10px;
    line-height: 100%;
    color: #0297a1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .inputfile + label {
    font-size: 1.0em;
    font-weight: 300;
    color: teal;
    /*background-color: white;*/
    display: inline-block;
    cursor: pointer;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: rgb(239, 243, 243);
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.button_save_document_oppredy {
    width: 10%;
    height: 38px;
    border: none;
    border-radius: 5px;
    margin: 20px;
    float: right;
    background-color: teal;
    outline: none;
    font-size: 18px;
    font-weight: bold;
}
.button_cancle_document_oppredy {
    width: 10%;
    height: 38px;
    border: none;
    border-radius: 5px;
    margin: 20px;
    float: right;
    background-color: rgb(209, 202, 202);
    color: teal;
    outline: none;
    font-size: 18px;
    font-weight: bold;
}

.button_cancle_user {
    width: 10%;
    height: 38px;
    border: none;
    border-radius: 5px;
    margin: 20px;
    float: right;
    background-color: teal;
    outline: none;
    font-size: 18px;
    color: white;
    font-weight: bold;
}


.user_detail_container {
    width: 70%;
    /* height: 40vh; */
    margin: 20px auto;
    /* background-color: white; */
    border-radius: 20px;
    border: none;
   
}