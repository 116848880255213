body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.site-layout-content{
  height: calc(100% - 1rem) !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: 1rem;

}
.site-layout-content > *{
  height: calc(100% - 1rem) !important;
  width: 100%;
}
.ant-transfer-list{
  flex: 1;
}
.view-help{
  border: none;
}
.outline-item{
  border: 1px solid #ddd;
}
.hover-line:hover{
  text-decoration-line: underline;
  color: #007bff;
}
.ant-modal-wrap{
  pointer-events: stroke;
}
.show-btn{
  position: relative;
  transition: all .2s linear;
  
}
.show-btn.show{
  transform: rotate(90deg);
}
.btn-view{
  flex: 1;
  display: flex;
  justify-content: end;
}
.tox.tox-tinymce{
  border: none;
}
[src*="/static/tinymce/plugins/opmanual/callout.html"]{
  height: 600px !important;
}
.tox-dialog{
  overflow-y: auto !important;
}